import {
  Box,
  Typography,
} from '@mui/material';

import { ObjectType } from '../../../../../../../../../types/objectType';

export const DetailBoxCustomizable = (
  {
    title,
    description,
    customStyle,
  }: ObjectType,
) => (<Box
  style={customStyle}
>
  <Typography
    variant='body2'
    color="text.secondary"
    mr={2}
    gutterBottom
  >
    {title}
  </Typography>
  <Typography
    variant='subtitle2'
    gutterBottom
    marginLeft={'16px!important'}
  >
    {description}
  </Typography>
</ Box>);
