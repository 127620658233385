import { ObjectType } from '../../types/objectType';

// Config Files
import {
  INIT_FILTERS,
  LOCALSTORAGE_KEYS,
  LIST_TYPES,
  SELECT_OPTIONS_BY_FILTER,
  FILTER_TYPES,
} from '../../config';

// Helpers
import {
  getListsPayloadHelper,
  updatePageListingHelper,
  getUpdatedListPayloadHelper,
  areFilterAndTagsEquals,
} from '../../helpers/stateHelpers';
import API from '../../services/worksWorkersAPI';
import WORKERS_FILTERS from '../../config/filtersConfig/workersFilters';

// Constants
const initialData = {
  isUpdateLoading: false,
  isLoading: false,
  isError: false,
  civilWorks: {
    isFilterUpdated: false,
    isLoading: false,
    isError: false,
    moreResults: true,
    results: [],
  },
  workers: {
    isFilterUpdated: false,
    isLoading: false,
    isError: false,
    moreResults: true,
    results: [],
  },
  filters: { ...INIT_FILTERS },
  tags: {},
};

const localStorageKey = LOCALSTORAGE_KEYS.filtersParameters;

// Redux Types
const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
const GET_NEXT_PAGE_LIST_SUCCESS = 'GET_NEXT_PAGE_LIST_SUCCESS';
const GET_UPDATED_LISTS_BY_FILTER_SUCCESS = 'GET_UPDATED_LISTS_BY_FILTER_SUCCESS';
const GET_UPDATED_LISTS_BY_CLEAR_WORKER_FILTERS_SUCCESS = 'GET_UPDATED_LISTS_BY_CLEAR_WORKER_FILTERS_SUCCESS';
const TOGGLE_IS_FILTER_UPDATED = 'TOGGLE_IS_FILTER_UPDATED';
const TOGGLE_IS_ERROR = 'TOGGLE_IS_ERROR';
const TOGGLE_IS_LOADING = 'TOGGLE_IS_LOADING';
const GET_TRANSLATE_SELECT_OPTIONS = 'GET_TRANSLATE_SELECT_OPTIONS';

// Redux Reducer
export default function civilWorksPersonListsReducer(state = initialData, action: ObjectType) {
  if ([
    GET_LISTS_SUCCESS,
    GET_NEXT_PAGE_LIST_SUCCESS,
    GET_UPDATED_LISTS_BY_FILTER_SUCCESS,
    TOGGLE_IS_FILTER_UPDATED,
    TOGGLE_IS_ERROR,
    TOGGLE_IS_LOADING,
    GET_TRANSLATE_SELECT_OPTIONS,
    GET_UPDATED_LISTS_BY_CLEAR_WORKER_FILTERS_SUCCESS,
  ].includes(action.type))
    return { ...state, ...action.payload };
  return state;
};

// Redux Actions

/**
 * @function toggleIsError
 *
 * @description toggle Error value
 *
 * @param {string} listType - list type to update. No list means global error state.
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const toggleIsError = (invalidToken: boolean = false, listType = '') => async (dispatch: any, getState: any) => {
  const initialList = getState().civilWorksPersonLists;
  const { civilWorks, workers } = initialList;
  if (listType === LIST_TYPES.civilWorks) {
    return dispatch({
      type: TOGGLE_IS_ERROR,
      payload: {
        civilWorks: {
          ...civilWorks,
          isError: !civilWorks.isError
        },
        invalidToken,
        isUpdateLoading: false,
      },
    });
  }
  if (listType === LIST_TYPES.workers) {
    return dispatch({
      type: TOGGLE_IS_ERROR,
      payload: {
        workers: { ...workers, isError: !workers.isError },
        invalidToken,
        isUpdateLoading: false,
      },
    });
  }
  return dispatch({
    type: TOGGLE_IS_ERROR,
    payload: { isError: !initialList.isError, invalidToken },
  });
};

/**
 * @function toggleIsFilterUpdated
 *
 * @description toggle isFilterUpdated value by listType
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const toggleIsFilterUpdated = (listType: string) => async (dispatch: any, getState: any) => {
  const initialList = getState().civilWorksPersonLists;
  const { civilWorks, workers } = initialList;
  if (listType === LIST_TYPES.civilWorks) {
    return dispatch({
      type: TOGGLE_IS_FILTER_UPDATED,
      payload: { civilWorks: { ...civilWorks, isFilterUpdated: !civilWorks.isFilterUpdated } },
    });
  }
  if (listType === LIST_TYPES.workers) {
    return dispatch({
      type: TOGGLE_IS_FILTER_UPDATED,
      payload: { workers: { ...workers, isFilterUpdated: !workers.isFilterUpdated } },
    });
  }
};


/**
 * @function toggleIsLoading
 *
 * @description toggle Loading value
 *
 * @param {string} listType - list type to update. No list means global error state.
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const toggleIsLoading = (listType = '') => async (dispatch: any, getState: any) => {
  const initialList = getState().civilWorksPersonLists;
  const { civilWorks, workers } = initialList;
  if (listType === LIST_TYPES.civilWorks) {
    return dispatch({
      type: TOGGLE_IS_LOADING,
      payload: { civilWorks: { ...civilWorks, isLoading: !civilWorks.isLoading } },
    });
  }
  if (listType === LIST_TYPES.workers) {
    return dispatch({
      type: TOGGLE_IS_LOADING,
      payload: { workers: { ...workers, isLoading: !workers.isLoading } },
    });
  }
  if (listType === LIST_TYPES.civilWorksWorkers) {
    return dispatch({
      type: TOGGLE_IS_LOADING,
      payload: { isUpdateLoading: !initialList.isUpdateLoading },
    });
  }
  return dispatch({
    type: TOGGLE_IS_LOADING,
    payload: { isLoading: !initialList.isLoading },
  });
};

/**
 * @function getList
 *
 * @description get filtered lists from api and updates civilWorksWorker storage
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getLists = () => async (dispatch: any, getState: any) => {
  const initialList = getState().civilWorksPersonLists;
  const { filters, tags } = initialList;
  const resetPages = {
    pageWorkers: INIT_FILTERS.pageWorkers,
    pageCivilWorks: INIT_FILTERS.pageCivilWorks,
  };
  let finalFilters = { ...filters };
  let finalTags = { ...tags, workersActive: [SELECT_OPTIONS_BY_FILTER.workersActive.values[1]] };
  const payloadFromMemory = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

  if (areFilterAndTagsEquals(payloadFromMemory)) {
    finalFilters = { ...finalFilters, ...resetPages, ...payloadFromMemory.filters };
    finalTags = { ...finalTags, ...payloadFromMemory.tags }
  }

  localStorage.setItem(localStorageKey, JSON.stringify({ filters: finalFilters, tags: finalTags }));
  try {
    const response = await API.getWorksAndWorkers(finalFilters);
    const { data } = response;
    // console.log(data);
    if (response.status === 200) {
      const listsPayload = getListsPayloadHelper(
        initialList,
        data,
        finalFilters,
        finalTags,
      );
      return dispatch({
        type: GET_LISTS_SUCCESS,
        payload: {
          ...listsPayload,
          isLoading: !initialList.isLoading
        },
      });
    }
    return dispatch(toggleIsError(data.invalidToken));
  } catch (error) {
    return dispatch(toggleIsError());
  }
};

/**
 * @function updateListsByFilter
 *
 * @description get updated lists by filters
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const updateListsByFilter = (updatedFilter: ObjectType, tagsToUpdate: ObjectType) => async (dispatch: any, getState: any) => {
  const needsRevisionBoolean = tagsToUpdate[FILTER_TYPES.workersCvStatus]
    && (tagsToUpdate[FILTER_TYPES.workersCvStatus].length !== 1
      || tagsToUpdate[FILTER_TYPES.workersCvStatus][0].selectValue !== 'naranja');
  let initialList = getState().civilWorksPersonLists;
  initialList.workers.isFilterUpdated = true;
  initialList.civilWorks.isFilterUpdated = true;
  const { filters } = initialList;
  const resetPages = {
    pageWorkers: INIT_FILTERS.pageWorkers,
    pageCivilWorks: INIT_FILTERS.pageCivilWorks,
  };
  let finalFilters = { ...filters, ...resetPages, ...updatedFilter };
  if (needsRevisionBoolean) finalFilters[FILTER_TYPES.workersCvStatusReason] = [];

  try {
    const response = await API.getWorksAndWorkers(finalFilters);
    const { data } = response;
    if (response.status === 200) {
      const listsPayload = getListsPayloadHelper(initialList, data, finalFilters, tagsToUpdate);
      let { tags, filters } = listsPayload;
      if (needsRevisionBoolean) tags[FILTER_TYPES.workersCvStatusReason] = [];
      localStorage
        .setItem(localStorageKey, JSON.stringify({ filters, tags }));
      return dispatch({
        type: GET_UPDATED_LISTS_BY_FILTER_SUCCESS,
        payload: {
          ...listsPayload,
          isUpdateLoading: false
        },
      });
    }
    return dispatch(toggleIsError(data.invalidToken));
  } catch (error) {
    return dispatch(toggleIsError());
  }
};

/**
 * @function getNextListPage
 *
 * @description get updated filtered lists next page by from api and updates civilWorksWorker storage by list
 *
 * @param {string} listToUpdate - list type to update. No list means global error state.
 *
 * @author Manu Fernandez | mfernandezfreire
 *
 */
export const getNextListPage = (listToUpdate: string) => async (dispatch: any, getState: any) => {
  const initialList = getState().civilWorksPersonLists;
  const finalFilters = updatePageListingHelper(initialList, listToUpdate);

  try {
    const response = await API.getWorksAndWorkers(finalFilters);
    const { data } = response;
    if (response.status === 200) {
      const listsPayload = getUpdatedListPayloadHelper(initialList, data, listToUpdate);
      return dispatch({
        type: GET_NEXT_PAGE_LIST_SUCCESS,
        payload: listsPayload,
      });
    }
    return dispatch(toggleIsError(data.invalidToken, listToUpdate));
  } catch (error) {
    return dispatch(toggleIsError(false, listToUpdate));
  }
};

/**
 * @function removeWorkersFilters
 *
 * @description removes only workers column filters
 *
 * @author Alejandro Ugarte Ruiz | alexaur97
 *
 */
export const removeWorkersFilters = (allFilters: ObjectType) => async (dispatch: any, getState: any) => {
  dispatch({
    type: GET_UPDATED_LISTS_BY_CLEAR_WORKER_FILTERS_SUCCESS,
    payload: {
      isLoading: true
    },
  });
  let initialList = getState().civilWorksPersonLists;
  initialList.workers.isFilterUpdated = true;
  initialList.civilWorks.isFilterUpdated = true;
  const updatedFilters = allFilters;
  WORKERS_FILTERS.forEach((key) => {
    if (key in updatedFilters) updatedFilters[key] = null;
  });

  const finalFilters = {
    ...updatedFilters,
    pageWorkers: INIT_FILTERS.pageWorkers,
    pageCivilWorks: INIT_FILTERS.pageCivilWorks,
  };

  try {
    const response = await API.getWorksAndWorkers(finalFilters);
    const { data } = response;
    if (response.status === 200) {
      const workersTags: ObjectType = {};
      WORKERS_FILTERS.forEach((filter) => { workersTags[filter] = []; });
      const listsPayload = getListsPayloadHelper(initialList, data, finalFilters, workersTags);
      const { tags, filters } = listsPayload;
      localStorage.setItem(localStorageKey, JSON.stringify({ filters, tags }));
      return dispatch({
        type: GET_UPDATED_LISTS_BY_CLEAR_WORKER_FILTERS_SUCCESS,
        payload: {
          ...listsPayload,
          isUpdateLoading: false
        },
      });
    }
    return dispatch(toggleIsError(data.invalidToken));
  } catch (error) {
    return dispatch(toggleIsError());
  }
};
