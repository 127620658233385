import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  filterContainer: {
    paddingTop: '1rem',
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
    paddingBottom: '0.5rem',
    height: '100%',
    minHeight: '100%',
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
  },
  filterPaper: {
    borderRadius: '12px!important',
    paddingLeft: '0.6rem',
    paddingRight: '0.6rem',
    height: '100%',
  },
  infiniteScroll: {
    overflow: 'overlay',
    overflowX: 'hidden',
    minHeight: '90%',
    maxHeight: '90%',
    scrollbarGutter: 'stable',
    paddingTop: '0.5rem',
    paddingRight: '0.2rem',
    paddingBottom: '1.5rem',
    '&::-webkit-scrollbar': {
      position: 'fixed',
      width: 4,
      borderRadius: '30px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey',
      padding: 1,
      borderRadius: 10,
    },
    '&:hover::-webkit-scrollbar': {
      width: 6,
      borderRadius: '30px',
    },
  },
});
