import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';
import { namespaces } from '../../../../../../i18n/i18n.constants';

import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';

import { ObjectType } from '../../../../../../types/objectType';
import { getUrlFilterParamsToStore } from '../../../../../../helpers/urlHelpers';

import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';
import { CustomSelectMultiple } from '../CustomSelectMultiple/CustomSelectMultiple';

import {
  SELECT_OPTIONS_BY_FILTER,
  LIST_TYPES,
} from '../../../../../../config';
import { getTranslatedOptions } from '../../../../../../helpers/stringHelpers';
import { event } from '../../../../../../config/analyticsConfig';
import workWorkersAPI from '../../../../../../services/worksWorkersAPI';

const getAutocompleteValues = async (filterType: string) => {
  const searchText = '';
  try {
    const response = await workWorkersAPI.autocompleteServiceWorksWorkers(filterType, searchText || '');
    const { data } = response;
    if (response.status === 200) {
      const { results } = data;
      if (results && results.length > 0) {
        return data.results;
      }
    }
  } catch (error) {
    // console.log(error);
    return [];
  }
  return [];
};

interface SelectMultipleFilterProps {
  filterType: string;
}

export const SelectMultipleFilter = (props: SelectMultipleFilterProps) => {
  const { filterType } = props;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const dispatch = useDispatch();
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);

  const [selectOptions, setSelectOptions] = useState<ObjectType>([]);
  const [selectedOptions, setSelectedOptions] = useState<Array<string | undefined>>([]);
  const [tags, setTags] = useState<Array<ObjectType>>([]);

  const getSelectParsedOptions = (
    options: ObjectType,
    optionsFromTags: ObjectType,
  ) => options?.map((option: ObjectType) => {
    let selected = false;
    if (optionsFromTags && Array.isArray(optionsFromTags)) {
      optionsFromTags.forEach((optionFromTag: ObjectType) => {
        if (optionFromTag.name === option.name) {
          selected = true;
        }
      });
    }
    return ({ ...option, selected });
  });

  const getOptionsFromTags = async () => {
    const optionsFromTags = initialTags[filterType];
    const selectOpt = SELECT_OPTIONS_BY_FILTER[filterType];
    let options = SELECT_OPTIONS_BY_FILTER[filterType].values;
    if (
      selectOpt.type === 'autocomplete'
      && selectOptions.length === 0
    ) {
      options = options.concat(await getAutocompleteValues(filterType));
      options = getTranslatedOptions(options, t, filterType);
      options = getSelectParsedOptions(options, optionsFromTags);
      return setSelectOptions(options);
    }
    options = selectOptions;
    options = getSelectParsedOptions(options, optionsFromTags);
    return setSelectOptions(options);
  };

  const getInfoToDispatch = (
    optionValues: Array<string | undefined>,
    selectOptionsToChange: ObjectType,
  ) => {
    const updatedFilter = selectOptionsToChange.map(
      (option: ObjectType) => (optionValues.includes(option.name)
        ? { ...option, selected: true }
        : { ...option, selected: false }),
    );
    const filterSelected = updatedFilter.filter(
      (option: ObjectType) => option.selected === true && ({ ...option }),
    );
    setSelectedOptions(optionValues);
    setTags(filterSelected);
    const filtersParsed = getUrlFilterParamsToStore(filterSelected, filterType, event.ADD);
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    setSelectOptions(updatedFilter);
    return { filterToUpdate, filterSelected };
  };

  const dispatchSelected = (filterInfoToDispatch: ObjectType) => {
    const { filterToUpdate, filterSelected } = filterInfoToDispatch;
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    dispatch(updateListsByFilter(filterToUpdate, { [filterType]: filterSelected }));
  };

  useEffect(() => {
    const tagsToSet = initialTags[filterType] || [];
    setTags(tagsToSet);
    getOptionsFromTags();
  }, [initialTags]);

  const debounceSelectedInfo = useCallback(
    debounce(dispatchSelected, 700),
    [],
  );

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        tags={tags}
        setTags={setTags}
      >
        <CustomSelectMultiple
          filterType={filterType}
          customStyle={{ paddingTop: '0.75rem' }}
          selectOptions={selectOptions}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getInfoToDispatch={getInfoToDispatch}
          debounceSelectedInfo={debounceSelectedInfo}
          defaultValue={t(`filtersPlaceHolder.${filterType}`)}
          required={false}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
