import {
  Box,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../../../../../../../i18n/i18n.constants';

import { ObjectType } from '../../../../../../../../../../types/objectType';

import { getEuropeanNumberFormat } from '../../../../../../../../../../helpers/stringHelpers';

import { detailedInfoGroupStyle } from './detailedInfoGroupStyle';

import {
  AMOUNT_TYPES,
} from '../../../../../../../../../../config';

export const DetailedInfoGroup = (
  {
    code,
    description,
    amount,
    amountType,
    mainGroupStyle,
    firstElement,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const { sx } = detailedInfoGroupStyle;
  const {
    mainGroupContainer,
    mainGroupContainerFirstElement,
  } = sx;
  const mainGroupContainerByFirstElement = (firstElement)
    ? mainGroupContainerFirstElement
    : mainGroupContainer;
  return (<Box
    sx={mainGroupStyle ? mainGroupContainerByFirstElement : sx.subGroupContainer}
  >
    <Box
      sx={sx.descriptionContainer}
    >
      <Typography
        variant={mainGroupStyle ? 'subtitle2' : 'body2'}
        ml={mainGroupStyle ? 0 : 2}
        p={0}
        mb={0}
        sx= { !mainGroupStyle ? sx.body2 : undefined}

      >
        {code}
      </Typography>
      {/* </Box> */}
      <Typography
        variant={mainGroupStyle ? 'subtitle2' : 'body2'}
        p={0}
        ml={2}
        mb={0}
        sx= { mainGroupStyle ? sx.subtitle2 : sx.body2 }
      >
        {description}
      </Typography>
    </Box>
    <Box sx={sx.amountContainer}>
      <Typography
        variant={mainGroupStyle ? 'subtitle2' : 'body2'}
        p={0}
        mb={0}
      >
        {
          amount
            ? getEuropeanNumberFormat(amount)
            : null
        }
      </Typography>
    </Box>
    <Box sx={sx.amountTypeContainer}>
      <Typography
        p={0}
        mb={0}
        sx={
          amountType === AMOUNT_TYPES.tax
            ? sx.taxStyle
            : sx.noTaxStyle}
      >
        {amountType && t(`common.${amountType}`)}
      </Typography>
    </Box>
  </Box>);
};
