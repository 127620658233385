import {
  useState,
  useCallback,
  useEffect,
  SyntheticEvent,
} from 'react';

import debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';

import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { CustomAutocomplete } from './CustomAutocomplete/CustomAutocomplete';

import { ObjectType } from '../../../../../../types/objectType';
import { getUrlCombinedFilterParamsToStore } from '../../../../../../helpers/urlHelpers';
import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';

import { FILTER_TYPES, LIST_TYPES } from '../../../../../../config';
import { event } from '../../../../../../config/analyticsConfig';

interface AutocompleteMultipleChoiceProps {
  filterType: string;
  alwaysOpen?: boolean;
  required?: boolean;
  setAutocompleteTags?: (tags: any[]) => void;
  noDivider?: boolean;
  combinedFilterValue?: string;
  tooltipMessage?: string;
}

export const AutocompleteMultipleChoice = (props: AutocompleteMultipleChoiceProps) => {
  const {
    filterType,
    alwaysOpen,
    required,
    setAutocompleteTags,
    noDivider,
    combinedFilterValue,
    tooltipMessage,
  } = props;
  const dispatch = useDispatch();
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const tagsToSet = initialTags[filterType];
    if (Array.isArray(tagsToSet)) {
      setTags(tagsToSet);
      if (tags.length === 0 && tagsToSet.length > 0 && setAutocompleteTags) {
        setAutocompleteTags(tagsToSet);
      }
    }
  }, [initialTags]);

  const getFiltersToUpdate = (newFilters: ObjectType) => {
    const filtersFromOptions = newFilters
      .filter((filter: ObjectType) => (filter && filter.name) && filter);
    setTags(filtersFromOptions);
    if (setAutocompleteTags) setAutocompleteTags(filtersFromOptions);
    const filtersToStore = {
      filters: filtersFromOptions,
      combinedFilterValue: filterType === FILTER_TYPES.workersComplementaryTraining
        ? combinedFilterValue?.replace(/[.,]/g, '') : combinedFilterValue,
    };
    const filtersParsed = getUrlCombinedFilterParamsToStore(
      filtersToStore,
      filterType,
      event.ADD,
    );
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    return { filterToUpdate, filtersFromOptions };
  };

  const handleChangeSelected = (
    e: SyntheticEvent<Element, Event>,
    newFilters: ObjectType,
  ) => getFiltersToUpdate(newFilters);

  const dispatchSelected = (filterInfoToDispatch: ObjectType) => {
    const { filterToUpdate, filtersFromOptions } = filterInfoToDispatch;
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    dispatch(updateListsByFilter(filterToUpdate, { [filterType]: filtersFromOptions }));
  };

  const debounceSelectedInfo = useCallback(
    debounce(dispatchSelected, 600),
    [],
  );

  useEffect(
    () => {
      if (!isFirstRender) {
        const filterInfoToDispatch = getFiltersToUpdate(tags);
        dispatchSelected(filterInfoToDispatch);
      } else {
        setIsFirstRender(false);
      }
    },
    [combinedFilterValue],
  );

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        tags={tags}
        setTags={setTags}
        setAutocompleteTags={setAutocompleteTags}
        alwaysOpen={alwaysOpen}
        noDivider={noDivider}
        tooltipMessage={tooltipMessage}
      >
        <CustomAutocomplete
          filterType={filterType}
          tags={tags}
          handleChangeSelected={handleChangeSelected}
          debounceSelectedInfo={debounceSelectedInfo}
          required={required}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
