import axios from './axiosInterceptors';

const photoServiceWorkers = async (id: string, listType?: string): Promise<string> => {
  const response = await axios.get(
    `/v1/photo/${listType}/${id}`,
  );
  return response.data;
};

const photoServiceCivilWorks = async (url: string, listType?: string): Promise<string> => {
  const response = await axios.get(
    `/v1/photo/${listType}?fotoUrl=${url}`,
  );
  return response.data;
};

export default {
  photoServiceWorkers,
  photoServiceCivilWorks,
};
