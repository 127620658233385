/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { LOCALSTORAGE_KEYS } from '../config';
import { refreshToken } from './authContextProvider';

const api = axios.create({
  baseURL: process.env.REACT_APP_EMERGYA_SERVER_API_HOST,
});

/**
 * Go to login if the token has expired or go to error permisos if the user has not permission
 * @param error
 */
function handleSession(error: any): void {
  const errorsToReset = ['Token Expired', 'jwt malformed', 'invalid signature', 'jwt must be provided', 'Unauthorized'];
  // eslint-disable-next-line max-len
  if ((error?.response?.data?.status === 401 || error?.response?.status === 401) && errorsToReset.includes(error?.response?.data?.message || error?.response?.statusText)) {
    localStorage.removeItem('tokenJupiter');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    window.location.href = '/';
  } else if (error?.response?.data?.status === 403) {
    window.location.href = '/error-permisos';
  }
}

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem(LOCALSTORAGE_KEYS.user);
    if (user) {
      config.headers['x-access-token'] = user;
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error: any) => Promise.reject(error),
);

// Add a response interceptor
api.interceptors.response.use(
  (response: any) => response,
  async (err) => {
    const originalRequest = err.config;
    let error = err;
    // If the error response is a Blob, we need to parse it to JSON. Case export to excel
    if (err.response.data instanceof Blob) {
      error = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const result = JSON.parse(reader.result as string);
            resolve({ response: { status: result.status, data: result } });
          } catch (e) {
            resolve(reader.result);
          }
        };
        reader.onerror = reject;
        reader.readAsText(err.response.data);
      });
    }
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response?.status === 401 && error.response?.data?.message === 'Token Expired' && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        // Retry the original request with the new token
        originalRequest.headers['x-access-token'] = localStorage.getItem('tokenJupiter');
        return axios(originalRequest);
      } catch (errorRefresh) {
        // Handle refresh token error or redirect to login
        console.log(errorRefresh);
        handleSession(error);
      }
    } else {
      console.log(error);
      handleSession(error);
    }
    return Promise.reject(error);
  },
);

export default api;
