export const detailedInfoGroupStyle = {
  sx: {
    mainGroupContainerFirstElement: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.75rem',
    },
    mainGroupContainer: {
      display: 'flex',
      width: '100%',
      marginTop: '1.5rem',
      marginBottom: '0.75rem',
    },
    subGroupContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.25rem',
    },
    descriptionContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
    },
    amountContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      width: '15%',
    },
    amountTypeContainer: {
      paddingLeft: '1.25rem',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '15%',
    },
    taxStyle: {
      fontSize: '0.625rem',
      fontWeight: 500,
      maxHeigth: '0.1rem',
    },
    noTaxStyle: {
      fontSize: '0.625rem',
      fontWeight: 500,
      color: '#BCBCBC',
    },
    subtitle2: {
      marginLeft: '16px!important',
    },
    body2: {
      marginLeft: '16px!important',
    },
  },
};
