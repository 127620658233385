import {
  Box,
  Paper,
  Grid,
  Button,
} from '@mui/material';

import { Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';

import filterListWrapperStyle from './filterListWrapperStyle';
import { namespaces } from '../../../../../../i18n/i18n.constants';
import WORKERS_FILTERS from '../../../../../../config/filtersConfig/workersFilters';
import { ObjectType } from '../../../../../../types/objectType';
import { removeWorkersFilters } from '../../../../../../redux/modules/civilWorkWorkerList';

interface FilterListWrapperProps {
  children: JSX.Element[],
  filterListTitle: string,
  personColumn?: boolean,
}

export const FilterListWrapper = (props: FilterListWrapperProps) => {
  const { children, filterListTitle, personColumn } = props;
  const classes = filterListWrapperStyle();
  const { t } = useTranslation(namespaces.pages.resumesSearch);

  const { filters } = useSelector((store: any) => store.civilWorksPersonLists);
  const workersFilters: ObjectType = Object.fromEntries(
    Object.entries(filters).filter(([key]) => WORKERS_FILTERS.includes(key)),
  );
  const disabled = Object.keys(workersFilters).length === 0
    || Object.values(workersFilters).every((val) => !val);

  const dispatch = useDispatch();
  const buttonColor = '#555555';

  return (
    <Grid
      className={classes.filterContainer}
    >
      <Paper
        className={classes.filterPaper}
        elevation={0}
      >
        <Box pt={2} pl={2} mb={2} className={classes.flexBox} >
          <Typography variant="h5">{t(`titles.${filterListTitle}`)}</Typography>
          {personColumn && <Button
            disabled={disabled}
            disableElevation
            onClick={() => dispatch(removeWorkersFilters(filters))}
            startIcon={<ClearIcon />}
            variant='outlined'
            sx={{
              borderRadius: '20px',
              fontSize: '14px',
              textTransform: 'none',
              color: buttonColor,
              minWidth: '150px',
              borderColor: buttonColor,
              ':hover':
              {
                borderColor: '#E4E4E4',
                backgroundColor: '#E4E4E4',
              },
              ':active':
              {
                borderColor: '#BBBBBB',
                backgroundColor: '#BBBBBB',
              },
              marginRight: '5px',
            }}
          >
            {t('button.clearFilters')}
          </Button>
          }
        </Box>
        <Box
          className={classes.infiniteScroll}
        >
          {children}
        </Box>
      </Paper>
    </Grid>
  );
};
