/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';
import { namespaces } from '../../../../../../i18n/i18n.constants';

import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';

import { ObjectType } from '../../../../../../types/objectType';
import { getUrlFilterParamsToStore } from '../../../../../../helpers/urlHelpers';

import { CustomSelect } from './CustomSelect/CustomSelect';

import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';

import {
  SELECT_OPTIONS_BY_FILTER,
  LIST_TYPES,
  FILTER_TYPES,
} from '../../../../../../config';
import { getTranslatedOptions } from '../../../../../../helpers/stringHelpers';
import { event } from '../../../../../../config/analyticsConfig';
import workWorkersAPI from '../../../../../../services/worksWorkersAPI';

const getAutocompleteValues = async (filterType: string) => {
  const searchText = '';
  try {
    const response = await workWorkersAPI.autocompleteServiceWorksWorkers(filterType, searchText || '');
    const { data } = response;
    if (response.status === 200) {
      const { results } = data;
      if (results && results.length > 0) {
        return data.results;
      }
    }
  } catch (error) {
    // console.log(error);
    return [];
  }
  return [];
};

interface SelectFilterProps {
  filterType: string;
  alwaysOpen?: boolean;
  disabled?: boolean;
  startUnselected?: boolean;
}

export const SelectFilter = (props: SelectFilterProps) => {
  const {
    filterType, alwaysOpen, disabled, startUnselected,
  } = props;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const dispatch = useDispatch();
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);

  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [selectValue, setSelectValue] = useState<any>('');
  const [tags, setTags] = useState<Array<ObjectType>>([]);

  const getOptions = async () => {
    const selectOpt = SELECT_OPTIONS_BY_FILTER[filterType];
    let options = SELECT_OPTIONS_BY_FILTER[filterType].values;
    if (selectOpt.type === 'autocomplete') {
      options = options.concat(await getAutocompleteValues(filterType));
    }
    options = getTranslatedOptions(options, t, filterType);
    setSelectOptions(options);
  };

  useEffect(() => {
    if (!startUnselected) {
      if (
        Array.isArray(initialTags[filterType])
        && initialTags[filterType].length > 0
      ) {
        const initialTagsTranslated = initialTags[filterType][0].name
          ? initialTags[filterType]
          : getTranslatedOptions(initialTags[filterType], t, filterType);
        setTags(initialTagsTranslated);
        return setSelectValue(initialTagsTranslated[0].name);
      }
      return setSelectValue(selectOptions[0]?.name);
    }
    return setSelectValue(undefined);
  }, [initialTags]);

  useEffect(() => {
    getOptions();
  }, []);

  const dispatchUdatedFilter = (
    e: React.ChangeEvent<HTMLInputElement>,
    options: Array<ObjectType>,
  ) => {
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    let updatedFilter = options.filter(
      (selectOption: ObjectType) => selectOption.name === e.target.value,
    );
    if (
      updatedFilter
      && updatedFilter[0]?.noFilterValue
    ) {
      updatedFilter = [];
    }
    const filtersParsed = getUrlFilterParamsToStore(updatedFilter, filterType, event.ADD);
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    if (!startUnselected) setSelectValue(e.target.value);
    setTags(updatedFilter);
    dispatch(updateListsByFilter(filterToUpdate, { [filterType]: updatedFilter }));
  };

  const debounceChangeSelect = useCallback(
    debounce(dispatchUdatedFilter, 600),
    [],
  );

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        tagsDisabled={filterType !== FILTER_TYPES.workersSpecialitiesExperience}
        tags={tags}
        setTags={setTags}
        alwaysOpen={alwaysOpen}
      >
        <CustomSelect
          customStyle={{ paddingTop: '0.75rem' }}
          selectValue={selectValue}
          selectOptions={selectOptions}
          handleSelectChange={(e: any) => debounceChangeSelect(e, selectOptions)}
          errorMessage={''}
          defaultValue={t(`filtersPlaceHolder.${filterType}`)}
          disabled={disabled}
          disableSelecOptionEmpty={true}
        />
      </FilterCollapsableWrapper>
    </>
  );
};
