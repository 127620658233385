import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ObjectType } from '../../../../../../../../../../../types/objectType';
import {
  reverseString,
  getEuropeanNumberFormat,
} from '../../../../../../../../../../../helpers/stringHelpers';
import { namespaces } from '../../../../../../../../../../../i18n/i18n.constants';

import { experiencieDescriptionSx } from './experiencieDescriptionSx';
import experiencieDescriptionStyle from './experiencieDescriptionStyle';

export const ExperienceDescription = (
  {
    inline,
    experience,
    translation,
    bbddKey,
    lastElement,
  }: ObjectType,
) => {
  const {
    startDate,
    receptionDate,
    endDate,
    subTitle1,
    subTitle1Description,
    subTitle2,
    description,
    aditionalDescription,
    aditionalDescriptionAlternative,
    aditionalDescriptionTitle,
    aditionalDescriptionSubtitle,
    subTitle1Duration,
  } = bbddKey;
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const TRADUCTION_BASE_PATH = `detailedInfoModal.workerDetailedInfo.${translation}.noInfoText.`;
  const { sx } = experiencieDescriptionSx;
  const styles = experiencieDescriptionStyle();

  const getDateInfo = () => {
    if (receptionDate && experience[receptionDate]) {
      return `${t(`${TRADUCTION_BASE_PATH}receptionDate`)}: ${reverseString(experience[receptionDate], '/')}`;
    }
    const startDateParsed = experience[startDate] ? reverseString(experience[startDate], '/') : t(`${TRADUCTION_BASE_PATH}noDate`);
    if (endDate && experience[endDate]) {
      const endDateParsed = experience[endDate] ? reverseString(experience[endDate], '/') : t(`${TRADUCTION_BASE_PATH}noDate`);
      return `${startDateParsed} - ${endDateParsed}`;
    }
    return startDateParsed;
  };

  const getSubtitleInfo = () => {
    let subTitle1Info = experience[subTitle1] ? experience[subTitle1] : t(`${TRADUCTION_BASE_PATH}subTitle1`);
    if (subTitle1Description && experience[subTitle1] && experience[subTitle1Description]) {
      subTitle1Info = `${experience[subTitle1]} (${experience[subTitle1Description]}h)`;
    }
    if (subTitle1Duration && experience[subTitle1] && experience[subTitle1Duration]) {
      subTitle1Info = `${experience[subTitle1]} (${experience[subTitle1Duration]})`;
    }
    if (endDate) {
      const subTitle2Info = experience[subTitle2] ? experience[subTitle2] : t(`${TRADUCTION_BASE_PATH}subTitle2`);
      return `${subTitle1Info} · ${subTitle2Info}`;
    }
    return subTitle1Info;
  };

  const getStyleType = (inlineStyleType: string) => {
    if (inlineStyleType) {
      return lastElement
        ? sx.inlineContainerLastElement
        : sx.inlineContainer;
    }
    return lastElement
      ? sx.containerLastElement
      : sx.container;
  };

  const dateInfo = getDateInfo();
  const subTitleInfo = getSubtitleInfo();
  let descriptionInfo = experience[description] ? experience[description] : t(`${TRADUCTION_BASE_PATH}description`);
  if (descriptionInfo.includes('•')) descriptionInfo = descriptionInfo.replaceAll('•', '*');
  if (descriptionInfo.includes('* ')) descriptionInfo = descriptionInfo.replaceAll('* ', '</br>- ');

  return (
    <Grid
      item
      key={uuidv4()}
      sx={getStyleType(inline)}
    >
      {
        !inline ? (
          <>
            <div className={styles.date}>
              {dateInfo}
            </div>
            <div className={styles.degree}>
              {subTitleInfo}
            </div>
            {
              (
                aditionalDescription
                && experience[aditionalDescription]
                && experience[aditionalDescription][aditionalDescriptionTitle]
              )
              && <Box
                style={sx.aditionalDescription}
                bottom={experience[aditionalDescriptionAlternative] ? '' : '3px'}
              >
                <div className={styles.aditionalDescriptionTitle}>
                  {experience[aditionalDescription][aditionalDescriptionTitle]}
                </div>

                <div className={styles.aditionalDescriptionSubtitle}>
                  {getEuropeanNumberFormat(
                    experience[aditionalDescription][aditionalDescriptionSubtitle],
                  )}
                </div>
              </Box>
            }
            {(
              aditionalDescriptionAlternative
              && experience[aditionalDescriptionAlternative]
            )
              && <> {experience[aditionalDescriptionAlternative]} </>
            }
            <div className={styles.body}>
              <p
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: descriptionInfo }} />
            </div>
          </>
        ) : (
          <Box
            sx={lastElement ? sx.inlineTypographyContainerLast : sx.inlineTypographyContainer}
          >
            <div className={styles.inlineSubtitle1}>
              {dateInfo}
            </div>
            <div className={styles.inlineSubtitle2}>
              {subTitleInfo}
            </div>
            <div className={styles.inlineBody}>
              {descriptionInfo}
            </div>
          </Box>
        )
      }
    </Grid >
  );
};
