import react, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ClearIcon from '@mui/icons-material/Clear';

import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { CustomSelect } from '../SelectFilter/CustomSelect/CustomSelect';
import { getTranslatedOptions } from '../../../../../../helpers/stringHelpers';
import { namespaces } from '../../../../../../i18n/i18n.constants';

import autocompleteAndSelectStyle from './autocompleteAndSelectStyle';
import { CustomAutocomplete } from '../AutocompleteMultichoice/CustomAutocomplete/CustomAutocomplete';
import { getUrlCombinedFilterParamsToStore } from '../../../../../../helpers/urlHelpers';
import { ObjectType } from '../../../../../../types/objectType';
import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';

import { LIST_TYPES } from '../../../../../../config';
import { CombinedFilterCollapsableWrapper } from '../../Wrappers/CombinedFilterCollapsableWrapper/combinedFilterCollapsableWrapper';
import { event } from '../../../../../../config/analyticsConfig';
import { SpecialitiesPopup } from './SpecialitiesPopup/SpecialitiesPopup';

interface AutocompleteAndSelectProps {
  filterType: string;
  combinedFilterType: string;
  infoMessage?: string;
  tooltipMessage?: string;
}

export const AutocompleteAndSelect = (props: AutocompleteAndSelectProps) => {
  const {
    filterType, combinedFilterType, infoMessage, tooltipMessage,
  } = props;
  const dispatch = useDispatch();
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const { t } = useTranslation(namespaces.pages.resumesSearch);

  const selectOptions = getTranslatedOptions([
    { translateValue: 'all', selectValue: '0' },
    { translateValue: 'zero', selectValue: '0' },
    { translateValue: 'three', selectValue: '3' },
    { translateValue: 'five', selectValue: '5' },
    { translateValue: 'ten', selectValue: '10' },
  ], t, combinedFilterType);

  const [combinedFilterValue, setCombinedFilterValue] = useState<any>(selectOptions[0]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const tagsToSet = initialTags[filterType];
    if (Array.isArray(tagsToSet) && (tags.length === 0 || tagsToSet.length === 0)) {
      setTags(tagsToSet);
    }
  }, [initialTags]);

  const getFiltersToUpdate = (newFilters: ObjectType[]) => {
    const filtersFromOptions = newFilters
      .filter((filter: ObjectType) => (filter && filter.name) && filter);
    setTags(filtersFromOptions);
    const filtersToStore = {
      filters: filtersFromOptions,
      combinedFilterValue: combinedFilterValue.selectValue,
    };
    const filtersParsed = getUrlCombinedFilterParamsToStore(
      filtersToStore,
      filterType,
      event.ADD,
    );
    const filterToUpdate = {
      [filterType]: filtersParsed,
    };
    return { filterToUpdate, filtersFromOptions };
  };

  const handleChangeSelected = (
    e: SyntheticEvent<Element, Event>,
    newFilters: ObjectType[],
  ) => getFiltersToUpdate(newFilters);

  const dispatchSelected = (filterInfoToDispatch: ObjectType) => {
    const { filterToUpdate, filtersFromOptions } = filterInfoToDispatch;
    dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
    dispatch(updateListsByFilter(filterToUpdate, { [filterType]: filtersFromOptions }));
  };

  const debounceSelectedInfo = useCallback(
    debounce(dispatchSelected, 600),
    [],
  );

  useEffect(() => {
    if (!isFirstRender) {
      const filterInfoToDispatch = getFiltersToUpdate(tags);
      debounceSelectedInfo(filterInfoToDispatch);
    } else {
      setIsFirstRender(false);
    }
  }, [combinedFilterValue]);

  const [openSpecialities, setOpenSpecialities] = useState(false);

  const firstChildComponent = <CustomAutocomplete
    filterType={filterType}
    tags={tags}
    handleChangeSelected={handleChangeSelected}
    debounceSelectedInfo={debounceSelectedInfo}
    required
  />;

  const secondChildComponent = <>
    {openSpecialities
      && <SpecialitiesPopup
        handleCloseSpecialities={() => setOpenSpecialities(false)}
      />
    }
    <CustomSelect
      customStyle={{ paddingTop: '0.75rem' }}
      selectValue={combinedFilterValue.name}
      selectOptions={selectOptions}
      handleSelectChange={(e: any) => setCombinedFilterValue(selectOptions
        .find((opt) => opt.name === e.target.value))}
      errorMessage={''}
      disabled={tags.length === 0}
      disableSelecOptionEmpty={true}
    />
    <Button
      disableElevation
      onClick={() => setOpenSpecialities(true)}
      variant='outlined'
      sx={{
        borderRadius: '20px',
        padding: '0 15px',
        fontSize: '12px',
        textTransform: 'none',
        color: '#555555',
        borderColor: '#555555',
        margin: '5px 0 15px 0.75rem',
        ':hover':
        {
          borderColor: '#E4E4E4',
          backgroundColor: '#E4E4E4',
        },
        ':active':
        {
          borderColor: '#BBBBBB',
          backgroundColor: '#BBBBBB',
        },
      }}
    >
      {t('specialities.show')}
    </Button>
  </>;

  return (
    <CombinedFilterCollapsableWrapper
      filterType={filterType}
      tags={tags}
      firstChild={firstChildComponent}
      secondChild={secondChildComponent}
      setTags={setTags}
      combinedFilterValue={combinedFilterValue}
      tooltipMessage={tooltipMessage}
    />
  );
};
