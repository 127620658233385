import axios from './axiosInterceptors';
import { ENDPOINTS, URL_FILTERS_PARAMS, URL_KEY_PARAMS } from '../config';
import { ObjectType } from '../types/objectType';
import { StatusChangeObject } from '../types/statusChangeObject';

const autocompleteServiceWorksWorkers = async (filterType: string, value: string) => {
  const baseUrl = ENDPOINTS.v1.autocomplete;
  const searchParam = `?${URL_KEY_PARAMS[filterType]}${value}`;
  const url = baseUrl + searchParam;
  const response = await axios.get(url);
  return response;
};

const getWorksAndWorkers = async (filters: ObjectType) => {
  const baseUrl = ENDPOINTS.v1.filter;
  let paramsToAdd = '';
  const urlFilterParams = Object.values(URL_FILTERS_PARAMS);
  Object.keys(filters).forEach((key) => {
    if (
      filters[key]
      && (
        ((typeof (filters[key]) === 'string') && filters[key].length > 0)
        || (typeof (filters[key]) === 'number')
      )
    ) {
      urlFilterParams.forEach((filterParam) => {
        if (filterParam.includes(`{${key}}`)) {
          const textToAdd = filterParam.replace(`{${key}}`, filters[key]);
          paramsToAdd += textToAdd;
        }
      });
    }
  });
  const url = baseUrl + paramsToAdd;
  const response = await axios.get(url);
  return response;
};

const updateStatus = async (idEmployee: string, body: StatusChangeObject) => {
  const baseUrl = ENDPOINTS.v1.employee;
  const searchParam = `/${idEmployee}/history`;
  const url = baseUrl + searchParam;
  const response = await axios.post(
    url,
    {
      color: body.color,
      comentario: body.comentario,
      usuario: body.usuario,
      fecha: body.fecha,
    },
  );
  return response;
};

export default {
  autocompleteServiceWorksWorkers,
  getWorksAndWorkers,
  updateStatus,
};
