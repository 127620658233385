import {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { namespaces } from '../../../../../../../../../i18n/i18n.constants';

import { ObjectType } from '../../../../../../../../../types/objectType';

import { detailBoxShowMoreStyle } from './detailBoxShowMoreStyle';

export const DetailBoxShowMore = (
  {
    title,
    value,
    maxLengthTextShowed,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const TRADUCTION_BASE_PATH = 'detailedInfoModal.';
  const SHOW_MORE_TEXT = t(`${TRADUCTION_BASE_PATH}showMore`);
  const SHOW_LESS_TEXT = t(`${TRADUCTION_BASE_PATH}showLess`);
  const [showMore, setShowMore] = useState(false);
  const [textToShow, setTextToshow] = useState(value);
  const [infoText, setInfoText] = useState(SHOW_MORE_TEXT);

  useEffect(() => {
    if (showMore) {
      setInfoText(SHOW_LESS_TEXT);
      return setTextToshow(value);
    }
    setInfoText(SHOW_MORE_TEXT);
    const shortTextToShow = `${value.slice(0, maxLengthTextShowed)}...`;
    return setTextToshow(shortTextToShow);
  }, [showMore]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const { sx } = detailBoxShowMoreStyle;
  return (
    <>
      <Box
        sx={sx.container}
      >
        <Typography
          variant='body2'
          color="text.secondary"
          gutterBottom
          sx={sx.title}
        >
          {title}
        </Typography>
        {
          (value.length > maxLengthTextShowed)
          && (<Box
            sx={sx.descriptionContainer}
          >
            <Typography
              variant='body2'
              ml={2}
              gutterBottom
              dangerouslySetInnerHTML={{ __html: textToShow }}
            />
            <Box
              sx={sx.showMoreContainer}
            >
              <Typography
                variant='body2'
                gutterBottom
                onClick={() => handleShowMore()}
                sx={sx.showMore}
              >
                {infoText}
              </Typography>
            </Box>
          </Box>)
        }
      </Box>
    </>
  );
};
