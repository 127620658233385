/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import {
  useState,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Grid,
} from '@material-ui/core';

import { FilterCollapsableWrapper } from '../../Wrappers/FilterCollapsableWrapper/FilterCollapsableWrapper';
import { FilterDialogWrapper } from '../../Wrappers/FilterDialogWrapper/FilterDialogWrapper';

import COMPLEX_FILTER_TYPES from '../../../../../../config/filtersConfig/complexFilterTypes';
import { getNumbersFromString } from '../../../../../../helpers/stringHelpers';

import { CustomSelect } from '../SelectFilter/CustomSelect/CustomSelect';
import { ObjectType } from '../../../../../../types/objectType';
import { namespaces } from '../../../../../../i18n/i18n.constants';
import {
  getUrlFilterParamsToStore,
} from '../../../../../../helpers/urlHelpers';
import {
  updateListsByFilter,
  toggleIsLoading,
} from '../../../../../../redux/modules/civilWorkWorkerList';
import { CustomButton } from '../../../../../Common/CustomButton/CustomButton';

import { NumberInput } from '../NumberInput/NumberInput';
import { FormControlWrapper } from '../../Wrappers/FormControlWrapper/FormControlWrapper';

import { LIST_TYPES } from '../../../../../../config';
import { event } from '../../../../../../config/analyticsConfig';
import workWorkersAPI from '../../../../../../services/worksWorkersAPI';

export const ClassificationGroups = ({ filterType }: ObjectType) => {
  const {
    tags: initialTags,
  } = useSelector((store: ObjectType) => store.civilWorksPersonLists);
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const [tags, setTags] = useState<Array<ObjectType>>([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [clasificationGrupoValue, setClasificationGrupoValue] = useState('none');
  const [
    classificationGrupoOptions,
    setClassificationGrupoOptions,
  ] = useState<Array<ObjectType>>([]);
  const [clasificationGrupoError, setClasificationGrupoError] = useState('');
  const [clasificationSubgrupoValue, setClasificationSubrupoValue] = useState('none');
  const [
    classificationSubgrupoOptions,
    setClassificationSubgrupoOptions,
  ] = useState<Array<ObjectType>>([]);
  const [clasificationSubgrupoError, setClasificationSubrupoError] = useState('');
  const [amountValue, setAmountValue] = useState('');
  const [amountError, setAmountError] = useState('');

  useEffect(() => {
    if (
      tags.length === 0
      && Array.isArray(initialTags[filterType])
      && initialTags[filterType].length > 0
    ) {
      setTags(initialTags[filterType]);
    }
  }, [initialTags]);

  const resetValues = () => {
    setClasificationGrupoValue('none');
    setClasificationSubrupoValue('none');
    setClassificationGrupoOptions([]);
    setClassificationSubgrupoOptions([]);
    setAmountValue('');
    setClasificationGrupoError('');
    setClasificationSubrupoError('');
    setAmountError('');
  };

  const getAutocompleteValues = async (
    e: React.ChangeEvent<HTMLInputElement>,
    groupType: string,
  ) => {
    const searchText = groupType === 'subgroup' && e.target.value.charAt(0);
    try {
      const response = await workWorkersAPI.autocompleteServiceWorksWorkers(
        COMPLEX_FILTER_TYPES[filterType][groupType].name,
        searchText || '',
      );
      const { data } = response;
      if (response.status === 200) {
        const { results } = data;
        if (results && results.length > 0) {
          return groupType !== 'subgroup'
            ? setClassificationGrupoOptions(data.results)
            : setClassificationSubgrupoOptions(data.results);
        }
        return groupType !== 'subgroup'
          ? setClassificationGrupoOptions([])
          : setClassificationSubgrupoOptions([]);
      }
    } catch (error) {
      // console.log(error);
    }
    return setClassificationGrupoOptions([]);
  };

  const handleOpen = async (openEvent: any) => {
    setOpen(true);
    getAutocompleteValues(openEvent, 'main');
  };

  const handleClose = () => {
    setOpen(false);
    resetValues();
  };

  const handleSelectGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClasificationGrupoValue(e.target.value);
    getAutocompleteValues(e, 'subgroup');
    setClasificationGrupoError('');
  };

  const handleSelectSubgroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClasificationSubrupoValue(e.target.value);
    setClasificationGrupoError('');
    setAmountError('');
  };

  const getParsedName = (nameToParse: string) => nameToParse.split('-')[0].trim();

  const getFilterInfoToSubmit = (
    classificationValue: string,
    groupType: string,
  ) => ({
    name: `${classificationValue} - ${amountValue}`,
    filterNameParsed: `${getParsedName(classificationValue)} - ${amountValue}`,
    filterConfig: {
      filterType,
      filterName: COMPLEX_FILTER_TYPES[filterType][groupType].name,
      filterQueryParams: COMPLEX_FILTER_TYPES[filterType][groupType].queryParams,
      filterValue: [classificationValue, getNumbersFromString(amountValue)],
    },
  });

  const getRepeatedFilter = (
    finalTags: Array<ObjectType>,
    filterInfoToSubmit: ObjectType,
  ) => finalTags
    .filter((tag) => tag.name === filterInfoToSubmit.name);

  const getFinalTags = (
    finalTags: ObjectType[],
    filterInfo: ObjectType,
  ) => {
    let tagsUpdated = false;
    const finalTagsUpdated = finalTags.map((finalTag) => {
      const finalTagCode = finalTag.filterNameParsed.split('-')[0].trim();
      const filterCode = filterInfo.filterNameParsed.split('-')[0].trim();
      const filterAmount = filterInfo.filterNameParsed.split('-')[1].trim();
      const { filterConfig } = filterInfo;
      const { filterValue } = filterConfig;
      if (finalTagCode === filterCode) {
        tagsUpdated = true;
        const name = `${finalTag.name.split('-')[0].trim()} - ${filterAmount}`;
        const filterNameParsed = `${finalTagCode} - ${filterAmount}`;
        return {
          ...finalTag,
          name,
          filterNameParsed,
          filterConfig: { ...filterConfig, filterValue },
        };
      }
      return finalTag;
    });
    if (tagsUpdated === false) {
      return [...finalTags, { ...filterInfo }];
    }
    return finalTagsUpdated;
  };

  const submitInfo = () => {
    if (
      (
        clasificationGrupoValue !== 'none'
        || clasificationSubgrupoValue !== 'none'
      )
      && amountValue.length > 0
    ) {
      dispatch(toggleIsLoading(LIST_TYPES.civilWorksWorkers));
      let finalTags = tags;
      const classificationValue = clasificationSubgrupoValue !== 'none'
        ? clasificationSubgrupoValue
        : clasificationGrupoValue;
      const groupType = clasificationSubgrupoValue !== 'none'
        ? 'subgroup'
        : 'main';
      const filterInfo = getFilterInfoToSubmit(classificationValue, groupType);
      const repeatedFilter = getRepeatedFilter(finalTags, filterInfo);
      if (repeatedFilter.length > 0) return handleClose();
      finalTags = getFinalTags(finalTags, filterInfo);
      const filtersParsed = getUrlFilterParamsToStore(finalTags, filterType, event.ADD);
      dispatch(updateListsByFilter(
        typeof (filtersParsed) === 'string'
          ? {}
          : filtersParsed,
        { [filterType]: finalTags },
      ));
      setTags(finalTags);
      return handleClose();
    }
    if (clasificationGrupoValue === 'none') {
      setClasificationGrupoError('Requerido');
    }
    if (amountValue.length === 0) {
      setAmountError('Requerido');
    }
  };

  return (
    <>
      <FilterCollapsableWrapper
        filterType={filterType}
        tags={tags}
        setTags={setTags}
        openDialog={open}
        handleClickButton={handleOpen}
        buttonText={'addClassification'}
      >
        <FilterDialogWrapper
          filterType={filterType}
          open={open}
          handleClose={handleClose}
          customStyle={
            {
              minWidth: '19vw',
              maxWidth: '19vw',
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '1rem',
              paddingLeft: '0.7rem',
              paddingRight: '0.7rem',
            }
          }
        >
          <CustomSelect
            selectValue={clasificationGrupoValue}
            selectOptions={classificationGrupoOptions}
            handleSelectChange={handleSelectGroupChange}
            defaultValue={t('filtersPlaceHolder.civilWorksClassificationGroup')}
            required={true}
            errorMessage={clasificationGrupoError}
            formHelperTextEnabled={true}
          />
          <CustomSelect
            selectValue={clasificationSubgrupoValue}
            selectOptions={classificationSubgrupoOptions}
            handleSelectChange={handleSelectSubgroupChange}
            disableSelecOptionEmpty={true}
            defaultValue={t('filtersPlaceHolder.civilWorksClassificationSubgroup')}
            errorMessage={clasificationSubgrupoError}
            formHelperTextEnabled={true}
          />
          <NumberInput
            textFieldValue={amountValue}
            setTextFieldValue={setAmountValue}
            placeHolderValue={t('filtersPlaceHolder.civilWorksClassificationAmount')}
            errorMessage={amountError}
            setErrorMessage={setAmountError}
          />
          <FormControlWrapper>
            <Grid
              container
              justifyContent="flex-end"
            >
              <CustomButton
                size={'large'}
                name={t('buttons.add', { ns: namespaces.common })}
                handleClick={submitInfo}
              />
            </Grid>
          </FormControlWrapper>
        </FilterDialogWrapper>
      </FilterCollapsableWrapper>
    </>
  );
};
